import React from "react";

import ProjectWhite from "./projectWhite";

import INFO from "../../data/user";

import "./styles/allProjectsWhite.css";

const AllProjectsWhite = () => {
    return (
        <div className="all-projects-container-white">
            {INFO.projects.map((project, index) => (
                <div className="all-projects-project-white" key={index}>
                    <ProjectWhite
                        logo={project.logo}
                        title={project.title}
                        descriptionImage={project.descriptionImage} 
                        linkText={project.linkText}
                        link={project.link}
                    />
                </div>
            ))}
        </div>
    );
};

export default AllProjectsWhite;
