import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";

import "./styles/portfolio_footer.css";
import "./styles/vitaminwater.css";

const VitaminWater = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "VITAMIN WATER - REBRANDING");
    const descriptionImage = sameProject.secondimage;
	const figmaLogo = sameProject.button1;
    const illustratorLogo = sameProject.button2;
    const photoshopLogo = sameProject.button3;
    const expressLogo = sameProject.button4;
    const aftereffectsLogo = sameProject.button5;
    const premierproLogo = sameProject.button6;
	const logopackaging = sameProject.thirdimage;
	const advertisements = sameProject.fourthimage;
	const linkText1 = sameProject.linkText1;
	const linkText2 = sameProject.linkText2;
	const link1 = sameProject.Link1;
	const link2 = sameProject.Link2;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`Vitamin Water Rebranding | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-vitaminwater">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="vitaminwater-columns">
						{/* Left Column (35% Width) */}
						<div className="vitaminwater-left-column">
							<div className="vitaminwater-content">
								<img src={descriptionImage} alt="vitaminwater" />
							</div>
						</div>

						{/* Right Column (65% Width) */}
						<div className="vitaminwater-right-column">
                            <div className="vitaminwater-title">
								VITAMIN WATER - REBRANDING
							</div>
							<div className="link-second-wrapper">
								<Link to={link1} className="link-wrapper">
									<div className="project-link-icon">
										<FontAwesomeIcon icon={faLink} />
									</div>
									<div className="project-link-text">{linkText1}</div>
								</Link>
								
								<Link to={link2} className="link-wrapper">
									<div className="project-link-icon-special">
										<FontAwesomeIcon icon={faLink} />
									</div>
									<div className="project-link-text-special">{linkText2}</div>
								</Link>
							</div>
                            <div className="vitaminwater-content">
                                For this rebranding project, my classmate and I collaborated to revitalize Vitamin Water, aiming to appeal to younger Gen Z consumers while embodying a premium step towards wellness for today's health-conscious individuals. Our efforts focused on updating the brand's story, slogan, logo, bottle design, and social media strategies.
                                <br></br><br></br>
                                Our vision for Vitamin Water was more than just hydration; it represented a premium step towards wellness, with each sip embodying mindful living, elevating anyone's daily routine.
                                <br></br><br></br>
                                In addition to crafting the brand story, we developed a physical prototype of the new bottle and created engaging social media campaigns to connect with younger audiences on Instagram. Our objective was to position Vitamin Water as a premium, healthy drink for on-the-go lifestyles, targeting the preferences and ambitions of the younger generation.
								<br></br><br></br>
								<div className="vitaminwater-video-container">
									<iframe
										width="100%"
										height="400"
										src="https://www.youtube.com/embed/PMEFFAAGAh0"
										title="Vitamin Water Rebranding"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
								</div>
							</div>
						</div>
					</div>
                    <div className="vitaminwater-software-title">
						DESIGN SOFTWARE
					</div>
					<div className="vitaminwater-software">
                        <a href="https://www.adobe.com/ca/products/illustrator/campaign/pricing.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS3-XAnPWYRfk-1tcn2wzQ5iwiWTqbRmKQsG8pKqNadhmZkQAg0YyhFUaAnBmEALw_wcB&sdid=KKQML&mv=search&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS3-XAnPWYRfk-1tcn2wzQ5iwiWTqbRmKQsG8pKqNadhmZkQAg0YyhFUaAnBmEALw_wcB:G:s&s_kwcid=AL!3085!3!495846071671!e!!g!!adobe%20illustrator!12231505436!122943072691&mv=search&gad_source=1">
							<img src={illustratorLogo} alt="illustrator" />
						</a>
                        <a href="https://www.adobe.com/ca/products/photoshop/landpa.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS3_j15pCCUqvFZ2HzwxlpNpX2RWXk9dCQ7umo82qMmilL5NADIjziTUaAi1AEALw_wcB&sdid=N3PCRP2Q&mv=search&mv2=paidsearch&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS3_j15pCCUqvFZ2HzwxlpNpX2RWXk9dCQ7umo82qMmilL5NADIjziTUaAi1AEALw_wcB:G:s&s_kwcid=AL!3085!3!673898814215!e!!g!!adobe%20photoshop!20219514620!149761557796&gad_source=1">
							<img src={photoshopLogo} alt="photoshop" />
						</a>
                        <a href="https://www.adobe.com/express/">
							<img src={expressLogo} alt="express" />
						</a>
                        <a href="https://www.adobe.com/ca/products/aftereffects.html">
							<img src={aftereffectsLogo} alt="aftereffects" />
						</a>
                        <a href="https://www.adobe.com/ca/products/premiere.html">
							<img src={premierproLogo} alt="premierpro" />
						</a>
					</div>
					<div className="bottom-images">
						<img src={logopackaging} alt="logo packaging"></img>
						<img src={advertisements} alt="advertisements"></img>
					</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default VitaminWater;
