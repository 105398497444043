import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faFigma } from "@fortawesome/free-brands-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import "./styles/footer.css";
import INFO from "../../data/user";

const Footer = () => {
    return (
        <React.Fragment>
            <div className="footer">
                <div className="homepage-socials">
                    <a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} className="homepage-social-icon" />
                    </a>
                    <a href={INFO.socials.figma} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFigma} className="homepage-social-icon" />
                    </a>
                    <a href={`mailto:${INFO.main.email}`} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faMailBulk} className="homepage-social-icon" />
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;