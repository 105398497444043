import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Homepage from "./pages/homepage";
import About from "./pages/about";
import Projects from "./pages/projects";
import EmotionalObject from "./pages/portfolio/emotionalobject";
import SamePersonDifferentEyes from "./pages/portfolio/samepersondifferenteyes";
import ShopGlo from "./pages/portfolio/shopglo";
import Tastemates from "./pages/portfolio/tastemates";
import UPlusMe from "./pages/portfolio/uplusme";
import VitaminWater from "./pages/portfolio/vitaminwater";
import WebRedesign from "./pages/portfolio/webredesign";
import GBDA from "./pages/portfolio/GBDA";
import Contact from "./pages/contact";
import Notfound from "./pages/404";

import { TRACKING_ID } from "./data/tracking";
import "./app.css";

function App() {
	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/about" element={<About />} />
				<Route path="/portfolio" element={<Projects />} />
				<Route path="/portfolio/emotionalobject" element={<EmotionalObject />} />
    			<Route path="/portfolio/samepersondifferenteyes" element={<SamePersonDifferentEyes />} />
				<Route path="/portfolio/shopglo" element={<ShopGlo />} />
				<Route path="/portfolio/tastemates" element={<Tastemates />} />
				<Route path="/portfolio/webredesign" element={<WebRedesign />} />
				<Route path="/portfolio/uplusme" element={<UPlusMe />} />
				<Route path="/portfolio/vitaminwater" element={<VitaminWater />} />
				<Route path="/portfolio/GBDA" element={<GBDA />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
		</div>
	);
}

export default App;
