import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/GBDA.css";

const GBDA = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "GBDA 303 - Assignment 1");
    const descriptionImage = sameProject.descriptionImage;
    const figmaLogo = sameProject.button1;
    const adobeIllustratorLogo = sameProject.button2;
	const adobePhotoshopLogo = sameProject.button3;
    const adobeExpressLogo = sameProject.button4;
	const image2 = sameProject.image2;
	const link1 = sameProject.Link1;
	const linkText1 = sameProject.LinkText1;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`GBDA 303 - Assignment 1 | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-GBDA">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
                    <div className="GBDA-title">
						GBDA 303: Assignment #1
					</div>
					<iframe width='100%' height='400px' src="https://api.mapbox.com/styles/v1/emmarachaello/clwymfv5d012t01qpgbg8940f.html?title=false&access_token=pk.eyJ1IjoiZW1tYXJhY2hhZWxsbyIsImEiOiJjbHdyeGc3MnQwNWw3MnFxOGUxOWR0ejV1In0.aZdNb5HgG8ucJrAOJ5VT6g&zoomwheel=false#11.33/49.2474/-123.0791" title="Streets" ></iframe>
                    <div className="GBDA-content">
						<div className="GBDA-legend">
							<img src={image2} alt="Legend"></img>
						</div>
						<img src={descriptionImage} alt="GBDA" />
						Infographic By: Emma Lo
						<div className="GBDA-software-title">
							Statement
						</div>
						The data I chose for assignment 1 was parks and schools in Vancouver, BC. Growing up in Vancouver, the streets and neighborhoods are very familiar to me which is why I chose this community. I chose to connect parks and schools because schools tend to have a park or multiple parks in the area. Comparing these data sets was fairly easy due to the locations that parks and schools reside in. This data relates to each other as it provides a place for outdoor activity and enjoyment within communities outside of schools. I began by organizing the data based on neighborhood names in Vancouver. I used pivot tables and charts to summarize the number of parks and schools within each neighborhood. This made it easier to spot trends and create comparisons between each data set. 
<br></br><br></br>
To design my infographic and visualize the data, I created charts in Excel. These charts helped me identify patterns and insights. I then moved these visual charts to Illustrator and constructed two initial line graphs. From the two line graphs I created, I came up with the idea to merge the two and create an area spline chart with graphics.   
<br></br><br></br>
To enhance the visuals of my infographic, I incorporated graphics such as a tree to represent parks and a book and graduation cap to represent schools. These graphics made the infographic more engaging and easier to understand.  In terms of colour palette, I wanted to reflect the themes of schools and parks as well as Vancouver itself. I was inspired by the colours of the Vancouver Canucks (the NHL hockey team in Vancouver) and used these colours for the text I wanted to stand out. This ensured a consistent and recognizable colour scheme throughout the project.
<br></br><br></br>
For the rest of the infographic, I kept the colours lighter and easy on the eyes using blues, greens, and pinks. These colours made the infographic visually appealing and also helped with differentiating the data points without overwhelming the audience. The goal of the infographic was to show the comparisons of schools and parks in different neighborhoods. By using a spline area chart and overlapping the data, I created a visual that is both digestible and comprehensible.
<br></br><br></br>
In addition to the spline area chart, I added a bar graph comparing independent vs public schools in the neighborhoods. This additional information provided a deeper understanding of the education sectors within Vancouver. 
<br></br><br></br>
Overall, this assignment was thoroughly thought out and planned out from the very beginning. I started with familiar data, organized it effectively using pivot tables, and then visualized it in a way that communicated these data sets. I used my creativity to create understandable graphics and chose a colour scheme that matched the overall information I was aiming to portray. 
<br></br><br></br>
This assignment has taught me how to analyze, understand, and transform data into a creative infographic that I am proud of. I hope to use my design skills and creations to ultimately help others understand complex data in an easy way.
						<div className="GBDA-software-title">
							Citations
						</div>
						BC Ministry of Education, "Schools," City of Vancouver, July 1, 2023. [Online]. Available: https://opendata.vancouver.ca/explore/dataset/schools/information/
						<br></br><br></br>
Vancouver Board of Parks and Recreation, "Parks," City of Vancouver, Nov. 19, 2020. [Online]. Available: https://opendata.vancouver.ca/explore/dataset/parks/information/
					</div>
					
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default GBDA;
