import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/tastemates.css";

const Tastemates = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const tastematesProject = INFO.projects.find(project => project.title === "TASTEMATES - RECIPE APP");
    const descriptionImage = tastematesProject.descriptionImage;
	const figmaLogo = tastematesProject.button1;
	const link1 = tastematesProject.Link1;
	const linkText1 = tastematesProject.LinkText1;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`Tastemates | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-tastemates">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
                    <div className="tastemates-container">
                        <div className="tastemates-title">
                            TASTEMATES
                        </div>
							<div className="link-second-wrapper">
							<Link to={link1} className="link-wrapper">
								<div className="project-link-icon">
									<FontAwesomeIcon icon={faLink} />
								</div>
								<div className="project-link-text">{linkText1}</div>
							</Link>
						</div>
                        <div className="tastemates-content">
                            <img src={descriptionImage} alt="tastemates" />
							<div className="tastemates-software-title">
								Design Software
							</div>
							<div className="tastemates-software">
								<a href="https://www.figma.com/">
									<img src={figmaLogo} alt="figmalogo" />
								</a>
							</div>
							For this project, I collaborated in a group to create a recipe app specifically for students transitioning into independent living. Our main focus was on making the app easy to use for beginners.
                            <br></br><br></br>
                            I worked on developing the mobile app prototype, ensuring it was user-friendly and engaging. By implementing vibrant colors and intuitive navigation, I aimed to simplify the cooking experience for our target audience.
                            <br></br><br></br>
                            While I primarily focused on the mobile design, I closely collaborated with my teammate to ensure consistency in the desktop version. We used the mobile prototype as a guide to maintain coherence across platforms.
                            <br></br><br></br>
                            Our main goal was to make cooking nutritious meals effortless for students embracing independence. By combining practical recipes with a seamless user experience, our recipe app aims to empower young adults on their journey toward independent living.
                        </div>
                    </div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Tastemates;
