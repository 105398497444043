import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/webredesign.css";

const WebRedesign = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "WEBSITE REDESIGN");
    const descriptionImage = sameProject.image1;
	const descriptionImage2 = sameProject.image3;
	const figmaLogo = sameProject.button1;
	const link1 = sameProject.Link1;
	const linkText1 = sameProject.LinkText1;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`Website Redesign | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-webredesign">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="webredesign-columns">
						{/* Left Column (35% Width) */}
						<div className="webredesign-left-column">
							<div className="webredesign-title">
								WEBSITE REDESIGN
							</div>
							<div className="link-second-wrapper">
								<Link to={link1} className="link-wrapper">
									<div className="project-link-icon">
										<FontAwesomeIcon icon={faLink} />
									</div>
									<div className="project-link-text">{linkText1}</div>
								</Link>
							</div>
							<div className="webredesign-content">
								<img src={descriptionImage} alt="webredesign" />
									<div className="webredesign-software-title">
											DESIGN SOFTWARE
								</div>
								<div className="webredesign-software">
									<a href="https://www.figma.com">
										<img src={figmaLogo} alt="Figma" />
									</a>
								</div>
								I redesigned a dim sum restaurant's website, addressing critical issues such as outdated design,
								low-quality images, and confusing navigation. I revitalized the brand by modernizing the logo, refining typography, and enriching the imagery with high-resolution photos for a polished aesthetic.
								<br></br><br></br>
								To enhance navigation, unnecessary tabs were removed and language options were added for seamless browsing. The menu layout underwent a transformation, now featuring clear photos and prices, alongside an intuitive add-to-cart option for effortless ordering.
								<br></br><br></br>
								With the goal of providing an exceptional user experience, the website now effortlessly guides visitors through the dining journey while captivating them with its stunning visuals.
							</div>
						</div>

						{/* Right Column (65% Width) */}
						<div className="webredesign-right-column">
							<div className="webredesign-content">
								<img src={descriptionImage2} alt="webredesign" />
							</div>
						</div>
					</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default WebRedesign;
