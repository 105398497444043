const INFO = {
	main: {
		title: "emma-lo.com",
		name: "Emma Lo",
		email: "erlo@uwaterloo.ca",
		logo: "../logo.png",
	},

	socials: {
		linkedin: "https://www.linkedin.com/in/emmarachaello/",
		figma: "https://www.figma.com/@emmalo",
	},

	homepage: {
		title: "Hello, I am Emma.",
		description: "I am a passionate designer and artist seeking opportunities to grow and apply skills towards creating meaningful designs that can positively impact people around the world.",
		title2: "Welcome to my portfolio!"
	},

	about: {
		title: "ABOUT ME",
		desc1: "Hello, my name is Emma Lo, a third year student at the University of Waterloo, specializing in Global Business and Digital Arts. From an early age, creativity has been a part of my life. Throughout my studies, I’ve completed various projects, refining my skills in design and marketing. This journey sparked my interest in marketing for brands, particularly through advertisements and social media campaigns.",
		description:
			"My coursework has not only sharpened my abilities but has also provided me with many opportunities to express my creativity. Whether it's crafting visually captivating designs or enhancing user experiences, I find fulfillment in creating to positively impact others through my passion for design and marketing. I aim to make a meaningful difference in the world through my creativity.",
	},

	articles: {
		title: "Navigating My Professional Journey: Daily Insights",
		description:
			"This space is dedicated to providing you with a behind-the-scenes look at my professional journey. Updated weekly, you can expect insights into the projects I'm tackling, the challenges I'm facing, and the milestones I'm achieving. Stay tuned to see what keeps me engaged day-to-day in the world of tech and engineering.",
	},

	projects: [
		{
			title: "VITAMIN WATER - REBRANDING",
			descriptionImage: "../smartwater.png",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/cpp/cpp.png",
			linkText: "View Project",
			link: "/portfolio/vitaminwater",
			button1: "../Frame 2.jpg",
			button2: "../Frame 3.jpg",
			button3: "../Frame 4.jpg",
			button4: "../Frame 6.jpg",
			button5: "../Frame 7.jpg",
			button6: "../Frame 8.jpg",
			secondimage: "../vitaminwater2.png",
			thirdimage: "../logo-packaging.png",
			fourthimage: "../advertisements.png",
			linkText1: "View Figma",
			linkText2: "View Instagram",
			Link1: "https://www.figma.com/community/file/1361858371043482745/rebranding-vitamin-water",
			Link2: "https://www.instagram.com/vitaminwaterredesign?igsh=MTAzYTAxbTVreGJiaQ%3D%3D"
		},
		
		{
			title: "U+ME - SOCIAL MEDIA",
			descriptionImage: "../leftright.png",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/cpp/cpp.png",
			linkText: "View Project",
			link: "/portfolio/uplusme",
			button1: "../Frame 2.jpg",
			button2: "../Frame 3.jpg",
			button3: "../Frame 4.jpg",
			button4: "../Frame 6.jpg",
			Link1: "https://www.figma.com/community/file/1361632755813994407/u-me-socials",
			LinkText1: "View Project"
		},
		{
			title: "SHOP GLO - SKINCARE APP",
			descriptionImage: "../shopglo_cropped.jpg",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/c/c.png",
			linkText: "View Project",
			link: "/portfolio/shopglo",
			button1: "../Frame 2.jpg",
			Link1: "https://www.figma.com/community/file/1361638092271225777/shopglo-app-prototype",
			LinkText1: "View Project"
		},

		{
			title: "TASTEMATES - RECIPE APP",
			descriptionImage: "../tastemates_cropped.jpg",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/c/c.png",
			linkText: "View Project",
			link: "/portfolio/tastemates",
			button1: "../Frame 2.jpg",
			Link1: "https://www.figma.com/community/file/1353500346142529874/tastemates-app-website",
			LinkText1: "View Project"
		},

		{
			title: "WEBSITE REDESIGN",
			descriptionImage: "../resturant.png",
			image2: "../resturant2.png",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/c/c.png",
			linkText: "View Project",
			link: "/portfolio/webredesign",
			image1: "../tastemates1.jpg",
			image3:"../tastemates2.jpg",
			button1:"../Frame 2.jpg",
			Link1: "https://www.figma.com/community/file/1353506662162926935/website-redesign",
			LinkText1: "View Project"
		},

		{
			title: "EMOTIONAL OBJECT",
			descriptionImage: "../emotionalobject.jpg",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/cpp/cpp.png",
			linkText: "View Project",
			link: "/portfolio/emotionalobject",
			button1: "../Frame 3.jpg",
			button2: "../Frame 4.jpg"
		},
		{
			title: "SAME PERSON DIFFERENT EYES",
			descriptionImage: "../sameperson.jpg",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "/portfolio/samepersondifferenteyes",
			button1: "../Frame 3.jpg",
			button2: "../Frame 4.jpg"
		},
		{
			title: "GBDA 303 - Assignment 1",
			descriptionImage: "../infographic.jpg",
			image2: "../legend.png",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "/portfolio/GBDA",
			button1: "../Frame 3.jpg",
			button2: "../Frame 4.jpg"
		},

		// filler
		// {
		// 	title: "VHDL-based Adder Modules",
		// 	description:"VHDL implementations of half-adders, full-adders, and a 4-bit adder for digital circuit design",
		// 	logo: "../vhdl.jpg",
		// 	linkText: "View Project",
		// 	link: "https://github.com/joeymaillette04/VHDL",
		// }
	],
};

export default INFO;
