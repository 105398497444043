import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/projectWhite.css";

const ProjectWhite = (props) => {
    const { logo, title, descriptionImage, linkText, link } = props;

    return (
        <React.Fragment>
            <div className="project-white">
                <Link to={link}>
                    <div className="project-container-white">
                    
                        <div className="project-description-image-white">
                            <img src={descriptionImage} alt="description" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="text-under">
                <div>{title}</div>
            </div>
        </React.Fragment>
    );
};

export default ProjectWhite;
