import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../../components/common/navBar";
import Footer from "./portfolio_footer";
import Logo from "../../components/common/logo";
import AllProjectsWhite from "../../components/projects/allProjectsWhite";

import INFO from "../../data/user";
import SEO from "../../data/seo";
import "./styles/portfolio_footer.css";
import "./styles/samepersondifferenteyes.css";

const SamePersonDifferentEyes = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");
    const sameProject = INFO.projects.find(project => project.title === "SAME PERSON DIFFERENT EYES");
    const descriptionImage = sameProject.descriptionImage;
	const adobeIllustratorLogo = sameProject.button1;
	const adobePhotoshopLogo = sameProject.button2;
	return (
		<React.Fragment>
			<Helmet>
				<title>{`Same Person Different Eyes | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content-sameperson">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="sameperson-columns">
						{/* Left Column (35% Width) */}
						<div className="left-column">
							<div className="sameperson-content">
								<img src={descriptionImage} alt="sameperson" />
							</div>
						</div>

						{/* Right Column (65% Width) */}
						<div className="right-column">
							<div className="sameperson-title">
								SAME PERSON DIFFERENT EYES
							</div>
                            My artwork represents diverse perspectives
of a single photo, symbolizing how individuals perceive us differently. It reflects the lesson learned through my growth: not everyone's view of us is the same. Embracing authenticity becomes crucial as I discovered that acceptance isn't universal; thus, there's no need to conceal our true selves to please everyone.
							<div className="sameperson-software-title">
										DESIGN SOFTWARE
							</div>
							<div className="sameperson-software">
								<a href="https://www.adobe.com/ca/products/illustrator/campaign/pricing.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB&sdid=KKQML&mv=search&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS387Mw6AVrVIcwhS1_5NM3VFBFdJrW3Lwf661_aR6r_tdObMWaISwsIaAkaWEALw_wcB:G:s&s_kwcid=AL!3085!3!495846071671!e!!g!!adobe%20illustrator!12231505436!122943072691&mv=search&gad_source=1">
									<img src={adobeIllustratorLogo} alt="illustrator" />
								</a>
								<a href="https://www.adobe.com/ca/products/photoshop/landpa.html?gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS38D4iQW0g-TBLFynjUF723xF5B2yYKKiX7emAOyhU4O8r5CkPIVNIsaAhK4EALw_wcB&sdid=N3PCRP2Q&mv=search&mv2=paidsearch&ef_id=Cj0KCQjwxeyxBhC7ARIsAC7dS38D4iQW0g-TBLFynjUF723xF5B2yYKKiX7emAOyhU4O8r5CkPIVNIsaAhK4EALw_wcB:G:s&s_kwcid=AL!3085!3!673898814215!e!!g!!adobe%20photoshop!20219514620!149761557796&gad_source=1">
									<img src={adobePhotoshopLogo} alt="photoshop" />
								</a>
							</div>
						</div>
					</div>
					<div className="portfolio-page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SamePersonDifferentEyes;
